import { get, post } from "@/utils/axios.js";

export const userEntityTypes = {
  customer: "customer",
  department: "department"
};

export const personInputTypes = {
  search: {
    value: "search",
    text: "搜索导入的被试者"
  },
  create: {
    value: "create",
    text: "新建被试者"
  }
};

export function initAndGetAllRoles() {
  return post("/customerinit/InitAndGetAllRoles");
}

export function getAllRoles() {
  return post("/role/GetAllRoles");
}

export async function initNewUser({
  loginName,
  loginPwd,
  displayName,
  roleName
}) {
  let response = await post("/customerinit/InitNewCustomer", {
    loginName,
    loginPwd,
    displayName,
    roleName
  });
  let userGuid = response.guid;
  await post("/customerinit/InitLbAndCategory", {
    customerGuid: userGuid
  });
}

export async function userAuth(username, password) {
  let response = await post("/adminauth/AdminAuthenticate", {
    userName: username,
    password: password
  });
  return {
    entityGuid: response.entityGuid,
    entity: response.entity,
    credentialGuid: response.credentialGuid,
    credentialName: response.credentialName,
    role: response.role,
    jwt: response.jwt
  };
}

export async function fetchUserEntityRole(userGuid) {
  let response = await get("/credential/CredentialEntityRoleFromEntityGuid", {
    t: new Date().getTime(),
    entityGuid: userGuid
  });
  return {
    entity: response.entity,
    role: response.role
  };
}

export function fetchSingletonCustInfo() {
  return get("/customer/SingletonCustomerInfo", {
    t: new Date().getTime()
  });
}

export function fetchUserCustInfo(userGuid) {
  return get("/customer/CustomerInfoFromGuid", {
    t: new Date().getTime(),
    customerGuid: userGuid
  });
}

export async function fetchPrimaryPersonInput(userGuid) {
  let response = await get(
    `/customer/PrimaryPersonInputFromCustomer/${userGuid}`
  );
  return response.value;
}

export async function saveUserCustInfo(
  userGuid,
  { userName, userDesc, website, primaryPersonInput }
) {
  await post("/customer/SaveCustomerInfo", {
    guid: userGuid,
    displayName: userName,
    description: userDesc,
    website,
    primaryPersonInput
  });
}
